export enum InsuranceCoverageType {
    aviationLiability = 'AVIATION_LIABILITY',
    baileesInsurance = 'BAILEES_INSURANCE',
    boatowners = 'BOATOWNERS',
    brokerInfo = 'BROKER_INFO',
    buildersRisk = 'BUILDERS_RISK',
    businessAutoLiability = 'BUSINESS_AUTO_LIABILITY',
    cargoLiability = 'CARGO_LIABILITY',
    collectivePersonalAccidentInsurance = 'COLLECTIVE_PERSONAL_ACCIDENT_INSURANCE',
    commercialAutoPhysicalDamage = 'COMMERCIAL_AUTO_PHYSICAL_DAMAGE',
    commercialCrimeLiability = 'COMMERCIAL_CRIME_LIABILITY',
    commercialGeneralLiability = 'COMMERCIAL_GENERAL_LIABILITY',
    commercialProperty = 'COMMERCIAL_PROPERTY',
    commercialTrailerInterchange = 'COMMERCIAL_TRAILER_INTERCHANGE',
    contingentAutoLiability = 'CONTINGENT_AUTO_LIABILITY',
    contingentCargoLiability = 'CONTINGENT_CARGO_LIABILITY',
    cropInsurance = 'CROP_INSURANCE',
    cyberLiability = 'CYBER_LIABILITY',
    declarationsPage = 'DECLARATIONS_PAGE',
    dwelling = 'DWELLING',
    employeeDishonesty = 'EMPLOYEE_DISHONESTY',
    employersLiability = 'EMPLOYERS_LIABILITY',
    employmentPracticesLiability = 'EMPLOYMENT_PRACTICES_LIABILITY',
    environmentalLiability = 'ENVIRONMENTAL_LIABILITY',
    farmowners = 'FARMOWNERS',
    fleet = 'FLEET',
    floodInsurance = 'FLOOD_INSURANCE',
    garageKeepersLiability = 'GARAGE_KEEPERS_LIABILITY',
    garageLiability = 'GARAGE_LIABILITY',
    holdHarmlessAgreement = 'HOLD_HARMLESS_AGREEMENT',
    homeowners = 'HOMEOWNERS',
    hullAndMachinery = 'HULL_AND_MACHINERY',
    inlandMarineCommercialAuto = 'INLAND_MARINE_COMMERCIAL_AUTO',
    leasedOrRentedEquipment = 'LEASED_OR_RENTED_EQUIPMENT',
    lifeInsurance = 'LIFE_INSURANCE',
    longshoreAndHarborWorkersCompensation = 'LONGSHORE_AND_HARBOR_WORKERS_COMPENSATION',
    lossOfIncome = 'LOSS_OF_INCOME',
    marineGeneralLiability = 'MARINE_GENERAL_LIABILITY',
    maritimeEmployersLiability = 'MARITIME_EMPLOYERS_LIABILITY',
    mediaLiability = 'MEDIA_LIABILITY',
    medicalErrorsAndOmissions = 'MEDICAL_ERRORS_AND_OMISSIONS',
    nonTruckingLiability = 'NON_TRUCKING_LIABILITY',
    occupationalAccident = 'OCCUPATIONAL_ACCIDENT',
    personalLiability = 'PERSONAL_LIABILITY',
    privatePassengerAuto = 'PRIVATE_PASSENGER_AUTO',
    productsAndCompletedOperations = 'PRODUCTS_AND_COMPLETED_OPERATIONS',
    professionalLiability = 'PROFESSIONAL_LIABILITY',
    protectionAndIndemnity = 'PROTECTION_AND_INDEMNITY',
    proofOfPayment = 'PROOF_OF_PAYMENT',
    publicAndProductsLiability = 'PUBLIC_AND_PRODUCTS_LIABILITY',
    riggersLiability = 'RIGGERS_LIABILITY',
    scheduleOfFormsAndEndorsements = 'SCHEDULE_OF_FORMS_AND_ENDORSEMENTS',
    sexualAbuseAndMolestationLiability = 'SEXUAL_ABUSE_AND_MOLESTATION_LIABILITY',
    studentAccidentMedical = 'STUDENT_ACCIDENT_MEDICAL',
    suretyBond = 'SURETY_BOND',
    technologyLiability = 'TECHNOLOGY_LIABILITY',
    thirdPartyFidelityBond = '3RD_PARTY_FIDELITY_BOND',
    umbrellaExcessLiability = 'UMBRELLA_EXCESS_LIABILITY',
    w9Form = 'W9_FORM',
    warehouseLegalLiability = 'WAREHOUSE_LEGAL_LIABILITY',
    watercraft = 'WATERCRAFT',
    workersCompensation = 'WORKERS_COMPENSATION',
    yachtInsurance = 'YACHT_INSURANCE',
}
