import { InsuranceCoverageType } from '@evidentid/insurance-facing-lib/models/insured-details';
import aviationLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-aviation-liability.svg';
import baileesInsuranceIcon from '@/modules/insured-details/assets/coverage-icon-bailees-insurance.svg';
import businessAutoIcon from '@/modules/insured-details/assets/coverage-icon-business-auto-liability.svg';
import cargoLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-cargo-liability.svg';
import collectivePersonalAccidentInsuranceIcon
    from '@/modules/insured-details/assets/collective-personal-accident-insurance.svg';
import commercialAutoPhysicalDamageIcon
    from '@/modules/insured-details/assets/coverage-icon-commercial-auto-physical-damage.svg';
import commercialCrimeIcon from '@/modules/insured-details/assets/coverage-icon-commercial-crime.svg';
import commercialPropertyIcon from '@/modules/insured-details/assets/coverage-icon-commercial-property.svg';
import commercialTrailerInterchangeIcon
    from '@/modules/insured-details/assets/coverage-icon-commercial-trailer-interchange.svg';
import contingentAutoLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-contingent-auto-liability.svg';
import contingentCargoLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-contingent-cargo-liability.svg';
import coverageIconBuildersRisk from '@/modules/insured-details/assets/coverage-icon-builders-risk.svg';
import coverageIconCrop from '@/modules/insured-details/assets/coverage-icon-crop.svg';
import coverageIconDwelling from '@/modules/insured-details/assets/coverage-icon-dwelling.svg';
import coverageIconFarmOwners from '@/modules/insured-details/assets/coverage-icon-farm-owners.svg';
import coverageIconFleet from '@/modules/insured-details/assets/coverage-icon-fleet.svg';
import coverageIconFloodInsurance from '@/modules/insured-details/assets/coverage-icon-flood-insurance.svg';
import coverageIconHomeOwners from '@/modules/insured-details/assets/coverage-icon-home-owners.svg';
import coverageIconLifeInsurance from '@/modules/insured-details/assets/coverage-icon-life-insurance.svg';
import coverageIconLossOfIncome from '@/modules/insured-details/assets/coverage-icon-loss-of-income.svg';
import cyberLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-cyber-liability.svg';
import defaultCoverageIcon from '@/modules/insured-details/assets/coverage-icon-default.svg';
import employeeDishonestyIcon from '@/modules/insured-details/assets/coverage-icon-employee-dishonesty.svg';
import employmentPracticesLiabilityIcon
    from '@/modules/insured-details/assets/coverage-icon-employment-practices-liability.svg';
import environmentalLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-environmental-liability.svg';
import garageKeepersLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-garage-keepers-liability.svg';
import garageLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-garage-liability.svg';
import generalLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-commercial-general-liability.svg';
import hullAndMachineryIcon from '@/modules/insured-details/assets/coverage-icon-hull-and-machinery.svg';
import inlandMarineCommercialAutoIcon
    from '@/modules/insured-details/assets/coverage-icon-inland-marline-commercial-auto.svg';
import longshoreAndHarborWorkersCompensationIcon
    from '@/modules/insured-details/assets/coverage-icon-longshore-and-harbor-workers-compensation.svg';
import marineGeneralLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-marine-general-liability.svg';
import maritimeEmployersLiabilityIcon
    from '@/modules/insured-details/assets/coverage-icon-maritime-employers-liability.svg';
import medicalErrorsAndOmissionsIcon
    from '@/modules/insured-details/assets/coverage-icon-medical-errors-and-omissions.svg';
import nonTruckingLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-non-trucking-liability.svg';
import occupationalIcon from '@/modules/insured-details/assets/coverage-icon-occupational.svg';
import personalLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-personal-liability.svg';
import privatePassengerAutoIcon from '@/modules/insured-details/assets/coverage-icon-private-passenger-auto.svg';
import productsAndCompletedOperationsIcon
    from '@/modules/insured-details/assets/coverage-icon-products-and-completed-operations.svg';
import professionalLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-professional-liability.svg';
import protectionAndIndemnity from '@/modules/insured-details/assets/coverage-icon-protection-and-indemnity.svg';
import publicAndProductsLiabilityIcon
    from '@/modules/insured-details/assets/coverage-icon-public-and-products-liability.svg';
import riggersLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-riggers-liability.svg';
import sexualAbuseAndMolestationLiabilityIcon
    from '@/modules/insured-details/assets/coverage-icon-sextual-abuse-and-molestation.svg';
import thirdPartyFidelityBondIcon from '@/modules/insured-details/assets/coverage-icon-3rd-party-fidelity-bond.svg';
import umbrellaExcessIcon from '@/modules/insured-details/assets/coverage-icon-umbrella-excess.svg';
import warehouseLegalLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-warehouse-legal-liability.svg';
import workersCompensationIcon from '@/modules/insured-details/assets/coverage-icon-workers-compensation.svg';
import mediaLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-media-liability.svg';
import technologyLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-technology-liability.svg';
import employerLiabilityIcon from '@/modules/insured-details/assets/coverage-icon-employers-liability.svg';
import boatownersIcon from '@/modules/insured-details/assets/coverage-icon-boatowners.svg';
import yachtInsuranceIcon from '@/modules/insured-details/assets/coverage-icon-yacht-insurance.svg';
import watercraftIcon from '@/modules/insured-details/assets/coverage-icon-watercraft.svg';
import leasedOrRentedEuipmentIcon from '@/modules/insured-details/assets/coverage-icon-leased-or-rented-equipment.svg';
import studentAccidentMedicalIcon from '@/modules/insured-details/assets/coverage-icon-student-accident-medical.svg';
import suretyBondIcon from '@/modules/insured-details/assets/coverage-icon-surety-bond.svg';
import w9FormIcon from '@/modules/insured-details/assets/coverage-icon-w-9-form.svg';
import holdHarmlessAgreementIcon from '@/modules/insured-details/assets/coverage-icon-hold-harmless-agreement.svg';
import declarationsPageIcon from '@/modules/insured-details/assets/coverage-icon-declarations-page.svg';
import proofOfPaymentIcon from '@/modules/insured-details/assets/coverage-icon-proof-of-payment.svg';
import scheduleOfFormsAndEndorsementsIcon
    from '@/modules/insured-details/assets/coverage-icon-schedule-of-forms-and-endorsements.svg';

const coverageTypeIcons: Record<InsuranceCoverageType, string> = {
    [InsuranceCoverageType.aviationLiability]: aviationLiabilityIcon,
    [InsuranceCoverageType.baileesInsurance]: baileesInsuranceIcon,
    [InsuranceCoverageType.boatowners]: boatownersIcon,
    [InsuranceCoverageType.brokerInfo]: defaultCoverageIcon,
    [InsuranceCoverageType.buildersRisk]: coverageIconBuildersRisk,
    [InsuranceCoverageType.businessAutoLiability]: businessAutoIcon,
    [InsuranceCoverageType.cargoLiability]: cargoLiabilityIcon,
    [InsuranceCoverageType.collectivePersonalAccidentInsurance]: collectivePersonalAccidentInsuranceIcon,
    [InsuranceCoverageType.commercialAutoPhysicalDamage]: commercialAutoPhysicalDamageIcon,
    [InsuranceCoverageType.commercialCrimeLiability]: commercialCrimeIcon,
    [InsuranceCoverageType.commercialGeneralLiability]: generalLiabilityIcon,
    [InsuranceCoverageType.commercialProperty]: commercialPropertyIcon,
    [InsuranceCoverageType.commercialTrailerInterchange]: commercialTrailerInterchangeIcon,
    [InsuranceCoverageType.contingentAutoLiability]: contingentAutoLiabilityIcon,
    [InsuranceCoverageType.contingentCargoLiability]: contingentCargoLiabilityIcon,
    [InsuranceCoverageType.cropInsurance]: coverageIconCrop,
    [InsuranceCoverageType.cyberLiability]: cyberLiabilityIcon,
    [InsuranceCoverageType.declarationsPage]: declarationsPageIcon,
    [InsuranceCoverageType.dwelling]: coverageIconDwelling,
    [InsuranceCoverageType.employeeDishonesty]: employeeDishonestyIcon,
    [InsuranceCoverageType.employersLiability]: employerLiabilityIcon,
    [InsuranceCoverageType.employmentPracticesLiability]: employmentPracticesLiabilityIcon,
    [InsuranceCoverageType.environmentalLiability]: environmentalLiabilityIcon,
    [InsuranceCoverageType.farmowners]: coverageIconFarmOwners,
    [InsuranceCoverageType.fleet]: coverageIconFleet,
    [InsuranceCoverageType.floodInsurance]: coverageIconFloodInsurance,
    [InsuranceCoverageType.garageKeepersLiability]: garageKeepersLiabilityIcon,
    [InsuranceCoverageType.garageLiability]: garageLiabilityIcon,
    [InsuranceCoverageType.holdHarmlessAgreement]: holdHarmlessAgreementIcon,
    [InsuranceCoverageType.homeowners]: coverageIconHomeOwners,
    [InsuranceCoverageType.hullAndMachinery]: hullAndMachineryIcon,
    [InsuranceCoverageType.inlandMarineCommercialAuto]: inlandMarineCommercialAutoIcon,
    [InsuranceCoverageType.leasedOrRentedEquipment]: leasedOrRentedEuipmentIcon,
    [InsuranceCoverageType.lifeInsurance]: coverageIconLifeInsurance,
    [InsuranceCoverageType.longshoreAndHarborWorkersCompensation]: longshoreAndHarborWorkersCompensationIcon,
    [InsuranceCoverageType.lossOfIncome]: coverageIconLossOfIncome,
    [InsuranceCoverageType.marineGeneralLiability]: marineGeneralLiabilityIcon,
    [InsuranceCoverageType.maritimeEmployersLiability]: maritimeEmployersLiabilityIcon,
    [InsuranceCoverageType.mediaLiability]: mediaLiabilityIcon,
    [InsuranceCoverageType.medicalErrorsAndOmissions]: medicalErrorsAndOmissionsIcon,
    [InsuranceCoverageType.nonTruckingLiability]: nonTruckingLiabilityIcon,
    [InsuranceCoverageType.occupationalAccident]: occupationalIcon,
    [InsuranceCoverageType.personalLiability]: personalLiabilityIcon,
    [InsuranceCoverageType.privatePassengerAuto]: privatePassengerAutoIcon,
    [InsuranceCoverageType.productsAndCompletedOperations]: productsAndCompletedOperationsIcon,
    [InsuranceCoverageType.professionalLiability]: professionalLiabilityIcon,
    [InsuranceCoverageType.protectionAndIndemnity]: protectionAndIndemnity,
    [InsuranceCoverageType.proofOfPayment]: proofOfPaymentIcon,
    [InsuranceCoverageType.publicAndProductsLiability]: publicAndProductsLiabilityIcon,
    [InsuranceCoverageType.riggersLiability]: riggersLiabilityIcon,
    [InsuranceCoverageType.scheduleOfFormsAndEndorsements]: scheduleOfFormsAndEndorsementsIcon,
    [InsuranceCoverageType.sexualAbuseAndMolestationLiability]: sexualAbuseAndMolestationLiabilityIcon,
    [InsuranceCoverageType.studentAccidentMedical]: studentAccidentMedicalIcon,
    [InsuranceCoverageType.suretyBond]: suretyBondIcon,
    [InsuranceCoverageType.technologyLiability]: technologyLiabilityIcon,
    [InsuranceCoverageType.thirdPartyFidelityBond]: thirdPartyFidelityBondIcon,
    [InsuranceCoverageType.umbrellaExcessLiability]: umbrellaExcessIcon,
    [InsuranceCoverageType.w9Form]: w9FormIcon,
    [InsuranceCoverageType.warehouseLegalLiability]: warehouseLegalLiabilityIcon,
    [InsuranceCoverageType.watercraft]: watercraftIcon,
    [InsuranceCoverageType.workersCompensation]: workersCompensationIcon,
    [InsuranceCoverageType.yachtInsurance]: yachtInsuranceIcon,
};

export function getCoverageTypeIcon(coverageType: InsuranceCoverageType): string {
    return coverageTypeIcons[coverageType] || defaultCoverageIcon;
}
