<template>
    <div class="EntityNameMatching">
        <div class="EntityNameMatching__container">
            <div v-if="isGlobalType" class="EntityNameMatching__alertContainer">
                <Alert type="regular">
                    <img class="EntityNameMatching__bannerIcon" :src="infoCircle" alt="info">
                    <span>Future global actions will be automatically reviewed based on your selection(s).</span>
                </Alert>
            </div>
            <Table class="EntityNameMatching__table">
                <template #head>
                    <HeadCell class="EntityNameMatching__fieldHeader">
                        Field
                    </HeadCell>
                    <HeadCell class="EntityNameMatching__valueHeader">
                        Value
                    </HeadCell>
                    <HeadCell class="EntityNameMatching__decisionHeader">
                        Decision
                    </HeadCell>
                </template>
                <Row hoverable>
                    <Cell class="EntityNameMatching__field">
                        {{ fieldName }}
                    </Cell>
                    <Cell class="EntityNameMatching__value">
                        <span v-if="value != null && value !==''">
                            {{ value }}
                        </span>
                        <EmptyValueDashSymbol v-else />
                        <div class="EntityNameMatching__valueError">
                            <FontAwesomeIcon :icon="faExclamationTriangle" />
                            <span>"{{ actionDetails.comparedTo }}"</span>
                            <span> required.</span>
                        </div>
                    </Cell>
                    <Cell class="EntityNameMatching__decisionHeader">
                        <div class="EntityNameMatching__decisionsContainer">
                            <Button
                                class="EntityNameMatching__rejectButton"
                                @click="resolve(false)"
                            >
                                Reject
                            </Button>
                            <Button
                                class="EntityNameMatching__acceptButton"
                                @click="resolve(true)"
                            >
                                Accept
                            </Button>
                        </div>
                    </Cell>
                </Row>
            </Table>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import startCase from 'lodash/startCase';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import {
        AcceptanceActionResolveInput, ActionReview,
        ActionType,
        EntityNameMatchingActionDetails,
    } from '@evidentid/insurance-facing-lib/models/insured-actions-review';
    import { typeNarrowing } from '@evidentid/dashboard-commons/utils/typeNarrowing';
    import { Cell, HeadCell, Row, Table } from '@evidentid/dashboard-commons/components/Table';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import infoCircle from '@/assets/icons/info-circle-transparent-background-icon.svg';
    import { getEntityMatchFieldName } from '@/modules/insured-actions-review/utils/getActionRequired';
    import { getActionScopeType } from '@/modules/insured-actions-review/utils/getActionScopeType';
    import { InsuredActionScopeType } from '@/modules/insured-actions-review/types';
    import EmptyValueDashSymbol
        from '@/modules/insured-actions-review/components/EmptyValueDashSymbol/EmptyValueDashSymbol.vue';

    @Component({
        components: { EmptyValueDashSymbol, Alert, Button, Cell, FontAwesomeIcon, HeadCell, Row, Table },
    })
    export default class EntityNameMatching extends Vue {
        @Prop({ type: Object })
        private actionReview!: ActionReview;

        private faExclamationTriangle = faExclamationTriangle;
        private infoCircle = infoCircle;

        private get actionDetails(): EntityNameMatchingActionDetails {
            return typeNarrowing<EntityNameMatchingActionDetails>(
                this.actionReview.action,
                this.actionReview.action.$action === ActionType.entityNameMatchingV1,
                'The actionReview object of this component has incorrect data, this is likely caused by mistake in the code.',
            );
        }

        private get fieldName(): string {
            return startCase(getEntityMatchFieldName(this.actionDetails));
        }

        private get value(): any {
            return this.actionDetails.given;
        }

        private get isGlobalType(): boolean {
            return getActionScopeType(this.actionDetails) === InsuredActionScopeType.global;
        }

        private resolve(accepted: boolean): void {
            const data: AcceptanceActionResolveInput = {
                $action: ActionType.entityNameMatchingV1,
                accepted,
            };
            this.$emit('resolve', data);
        }
    }
</script>
